var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "backend login" }, [
    _c("main", [
      _c("div", { staticClass: "row h-100" }, [
        _c(
          "div",
          {
            staticClass: "col-12 col-md-9 mx-auto login-container",
            staticStyle: { "max-width": "500px" },
          },
          [
            _c("form", { staticClass: "form-narrow py-4 col-12 pb-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _vm.loading
                  ? _c(
                      "div",
                      { staticClass: "loader-container" },
                      [_c("Loader")],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c("div", { staticClass: "text-center mb-4" }, [
                          _c(
                            "h1",
                            { staticClass: "h3 mb-3 font-weight-normal" },
                            [_vm._v(_vm._s(_vm.$t("login.createPassword")))]
                          ),
                        ]),
                        _vm.tokenStatus != null && _vm.tokenStatus.logoUrl
                          ? _c("div", { staticClass: "text-center" }, [
                              _c("img", {
                                staticClass: "logo",
                                attrs: { src: _vm.tokenStatus.logoUrl },
                              }),
                            ])
                          : _vm._e(),
                        _vm.tokenIsValid
                          ? [
                              _c("div", { staticClass: "mt-3 text-center" }, [
                                _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("login.resetPasswordDescription")
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("login.resetPasswordValidation")
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "password",
                                      state: _vm.$v.password.$dirty
                                        ? !_vm.$v.password.$error
                                        : null,
                                      placeholder: _vm.$t(
                                        "login.enterPassword"
                                      ),
                                    },
                                    model: {
                                      value: _vm.$v.password.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.password,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "$v.password.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "password",
                                      state: _vm.$v.confirmPassword.$dirty
                                        ? !_vm.$v.confirmPassword.$error
                                        : null,
                                      placeholder: _vm.$t(
                                        "login.confirmPassword"
                                      ),
                                    },
                                    model: {
                                      value: _vm.$v.confirmPassword.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.confirmPassword,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "$v.confirmPassword.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "button",
                                {
                                  ref: "resetButton",
                                  staticClass: "btn login-button btn-block",
                                  attrs: { disabled: _vm.$v.$invalid },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.confirm.apply(null, arguments)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("shared.save")) + " ")]
                              ),
                            ]
                          : _vm.newPasswordLinkSent
                          ? [
                              _c("div", { staticClass: "text-center" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("login.resetRequestSent")) +
                                    " "
                                ),
                              ]),
                            ]
                          : [
                              _vm.tokenStatus != null &&
                              _vm.tokenStatus.alreadyUsed
                                ? _c("div", { staticClass: "text-center" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("login.resetTokenAlreadyUsed")
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm.tokenStatus != null &&
                                  _vm.tokenStatus.isExpired
                                ? _c("div", { staticClass: "text-center" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("login.resetTokenExpired")
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("div", { staticClass: "text-center" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("login.resetTokenNotFound")
                                        ) +
                                        " "
                                    ),
                                  ]),
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _vm.tokenStatus != null &&
                                  (_vm.tokenStatus.alreadyUsed ||
                                    _vm.tokenStatus.isExpired)
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "link" },
                                          on: {
                                            click: _vm.resendResetPassword,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "login.resendResetTokenBtn"
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "link" },
                                          on: {
                                            click: _vm.goToResetPasswordPage,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "login.gotToResetPasswordBtn"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    ),
              ]),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }