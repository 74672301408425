














































































import { inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import { Validate } from 'vuelidate-property-decorators';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { Action } from 'vuex-class';
import { PasswordHelper } from '@/shared/helpers/password.helper';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { TokenStatus } from '../models/token-status.model';
import { IPasswordApiService } from '../services/password-api.service';
@Component
export default class ConfirmResetPassword extends Vue {
  @Validate({required, minLength: minLength(8), valid: (value: string) => PasswordHelper.validateStrength(value)})
  password = '';
  @Validate({required, minLength: minLength(8), sameAs: sameAs('password')})
  confirmPassword = '';

  loading = false;

  tokenStatus: TokenStatus | null = null;

  newPasswordLinkSent = false;

  @Action
  private confirmResetPassword!: (payload: { token: string, password: string }) => Promise<void>;
    
  @Action
  private resendResetPasswordToken!: (payload: { clubId: string, token: string }) => Promise<void>;

  @inject()
  private passwordApiService!: IPasswordApiService;

  async created(): Promise<void> {
    try {
      this.loading = true;
      let result = await this.passwordApiService.validateTokenStatus(this.token);      
      this.tokenStatus = result;
    } 
    catch {
      ToastHelper.showError(this.$root.$bvToast, this.$t('shared.errorMssage'));
      await this.$router.push('/').catch(ex => ex);
    }
    finally {
      this.loading = false;
    }
  }

  async confirm(): Promise<void> {
    if (this.$v.$invalid) {
      return;
    }
  
    const token = this.token;
    const password = this.confirmPassword;

    try {
      await this.confirmResetPassword({ token, password });
      ToastHelper.showSuccess(this.$root.$bvToast, this.$t('login.changePasswordConfirmation'));
    } catch {
      ToastHelper.showError(this.$root.$bvToast, this.$t('login.tokenExpired'));
    }

    this.$router.push('/login');    
  }

  goToResetPasswordPage () : void {
    this.$router.push('/reset-password'); 
  }

  async resendResetPassword() : Promise<void> {
    try {
      if (!this.tokenStatus)  return;

      const clubId = this.tokenStatus.clubId;
      const token = this.token;

      await this.resendResetPasswordToken({clubId, token});
      ToastHelper.showSuccess(this.$root.$bvToast, this.$t('login.newPasswordLinkSent'));
      this.newPasswordLinkSent = true;
    }
    catch {
      ToastHelper.showError(this.$root.$bvToast, this.$t('shared.errorMessage'));
    }
  }

  get token(): string {
    return this.$route.params.token;
  }

  get tokenIsValid () : boolean {
    return this.tokenStatus != null
      && !this.tokenStatus.isExpired
      && !this.tokenStatus.alreadyUsed;
  }
}
